<template>
  <v-app>
    <j-dialog
      dialog-name="login"
      :value="showLogin"
      title="Authenticate to continue..."
      max-width="400px"
      modal
      class="elevation-12"
    >
      <j-card-text>
        <!-- Login Error Messages -->
        <j-alert
          v-if="loginError && !resetMode"
          type="error"
          :message="loginError"
          class="mt-4"
        />

        <!-- Form -->
        <j-form
          model="auth.login"
          @submit="handleLogin"
        >
          <!-- Email -->
          <j-control
            name="email"
            :autofocus="true"
          />

          <!-- Password -->
          <j-control
            name="password"
            autocomplete="current-password"
          />
        </j-form>
      </j-card-text>

      <template #actions>
        <!-- Login Button -->
        <j-button
          label="Log In"
          :processing="login.saving"
          :disabled="login.pristine"
          @action="handleLogin"
        />
      </template>

      <template #options>
        <!-- Reset Password Button -->
        <j-button
          v-if="!login.saving"
          label="Forgot password?"
          type="option"
          @action="handleReset"
        />
      </template>
    </j-dialog>
  </v-app>
</template>

<script>

// Vuex
import { mapState } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      loginError: '',
      showLogin: true
    }
  },
  computed: {
    ...mapState({
      login: (state) => state.auth.login
    })
  },
  methods: {
    handleReset() {
      this.login.set('password', null)
      this.$router.push({ name: 'forgot-password' })
    },
    async handleLogin() {
      this.loginError = ''
      await this.login.save().then(() => {
        // Success, register JWT token
        this.$user.login(this.login.jwt)
        // Is this the login page or a dialog?
        if (this.$route.name == 'login') {
          // Back to whence you came
          this.$user.loginReturn()
        } else {
          // Close the dialog
          this.showLogin = false
        }
      }).catch(
        (error) => {
          console.error(error)
          if (error.response) {
            this.loginError = `Invalid email or password`
          }
        }
      )
    }
  }
}
</script>

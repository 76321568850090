<template>
  <v-app>
    <j-dialog
      dialog-name="forgot-password"
      title="Forgot Password?"
      :value="true"
      max-width="400px"
      modal
      class="elevation-12"
    >
      <j-card-text>
        <!-- Reset Messages -->
        <j-alert
          :message="alertMessage"
          class="mt-4"
        />

        <!-- Form -->
        <j-form
          model="auth.login"
          @submit="handleReset"
        >
          <!-- Email -->
          <j-control
            name="email"
            :autofocus="true"
          />
        </j-form>
      </j-card-text>

      <template #actions>
        <!-- Send Button -->
        <j-button
          label="Send"
          :processing="login.saving"
          :disabled="login.pristine || resetSent"
          @action="handleReset"
        />
      </template>

      <template #options>
        <!-- Cancel Reset Button -->
        <j-button
          label="Cancel"
          type="option"
          @action="cancelReset"
        />
      </template>
    </j-dialog>
  </v-app>
</template>

<script>

// Vuex
import { mapState } from 'vuex'

export default {
  name: 'Forgot',
  data() {
    return {
      resetMessage : '',
      resetSent: false
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState({
      login: (state) => state.auth.login
    }),
    alertMessage () {
      return this.resetMessage || 'Enter your email address to request a password reset and receive an email with instructions.'
    }
  },
  methods: {
    cancelReset() {
      this.resetMessage = ''
      this.$router.push({ name: 'login' })
    },
    handleReset() {
      this.login.reset().then(() => {
        this.resetSent = true
        this.resetMessage = `An email has been sent to <strong>${this.login.get('email')}</strong> with further instructions.`
      })
    }
  }
}
</script>

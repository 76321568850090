<template>

  <!-- Auth View -->
  <v-row>

    <v-col v-if="error">
      <j-alert
        type="error"
        :message="error"
        class="my-2"
      />
    </v-col>
    <v-col v-else cols="2" offset="5">

      <!-- Logo -->
      <!-- img
        src="../assets/images/ink19-logo.png"
        alt="Ink 19 Logo"
        style="display: block; margin: auto;"
      -->

      <!-- Login Modal -->
      <Login v-if="showLogin" />

      <!-- Forgot Password Modal -->
      <Forgot v-if="showForgot" />

    </v-col>
  </v-row>
</template>

<script>

// Components
import Forgot from '@/components/panels/auth/Forgot'
import Login from '@/components/panels/auth/Login'

// Vuex
import { mapState } from 'vuex'

export default {
  name: 'AuthView',
  // -------------
  // Components ==
  // -------------
  components: {
    Forgot,
    Login
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(to, _from, next) {
    next(vm => {
      if (to.name == 'password-reset') {
        const token = to.params.token
        vm.login.set({ token })
        // Validate password reset token
        vm.login.validateReset(token).then(() => {
          // Login user with returned token
          vm.$user.login(vm.login.jwt)
          // Send to profile
          vm.$router.push({ name: 'contributor-view', params: { slug: vm.$user.slug }, query: { resetPassword: 1 }})
        }).catch(() => {
          // Show error message
          vm.error = 'Invalid token. Please check your email for the latest version of this link, or request a new password reset.'
        })
      } else if (to.name == 'alias' && vm.$user.isAdmin) {
        // Execute alias maneuver
        const slug = to.params.slug
        vm.login.set({ slug })
        vm.login.alias().then(() => {
          // Login with the alias jwt
          vm.$user.login(vm.login.jwt)
          // Send home
          vm.$router.push({ name: 'home' })
        }).catch(
          (error) => console.log(error)
        )
      }
    })
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: 'Login'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState({
      login: (state) => state.auth.login
    }),
    showLogin () {
      return this.$route.name == 'login'
    },
    showForgot () {
      return this.$route.name == 'forgot-password'
    }
  },
  // -------
  // Data ==
  // -------
  data () {
    return {
      error: false
    }
  }
}
</script>
